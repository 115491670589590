<template>
  <LazyWorkspaceCreateModal
    v-model:visible="createWorkspaceModalVisible"
    @close="createWorkspaceModalVisible = false"
    @created="createWorkspaceModalVisible = false"
  />
  <UDropdown
    mode="click"
    :items="[orderedWorkspaceItems, actions]"
    class="w-full flex-1 hover:bg-white rounded-lg"
    :ui="{ padding: 'p-1', width: 'w-52', item: { padding: 'p-0' } }"
    :popper="{ strategy: 'absolute' }"
  >
    <template #item="{ item }">
      <div
        :class="[
          'flex-1 flex gap-2 p-2 rounded overflow-hidden ',
          {
            'bg-gray-100': item.id === currentWorkspace.id,
          },
        ]"
        data-test="workspace-dropdown-item"
        @click="changeWorkspace(item)"
      >
        <Avatar
          :id="item.id"
          size="xs"
          rounded="rounded"
          :src="item?.logo"
          :name="item?.name"
          :length="1"
        />
        <span class="truncate dark:text-white flex-1 text-left">
          {{ item?.name }}
        </span>
      </div>
    </template>
    <template #default="{ open }">
      <UButton
        color="gray"
        variant="ghost"
        class="px-2 py-1.5 max-w-[160px] hover:bg-white"
        trailing-icon="i-heroicons-chevron-down-20-solid"
        data-test="workspace-dropdown-btn"
        @click="!open && load()"
      >
        <div class="flex gap-2 items-center flex-1 max-w-[140px]">
          <Avatar
            :id="currentWorkspace.id"
            size="xs"
            rounded="rounded"
            :src="currentWorkspace.logo"
            :name="currentWorkspace.name"
            :length="1"
          />
          <span
            data-test="workspace-name"
            class="truncate dark:text-white font-medium text-sm flex-1"
          >
            {{ currentWorkspace?.name || 'Select workspace' }}
          </span>
        </div>
      </UButton>
    </template>
  </UDropdown>
</template>

<script setup lang="ts">
import type { DropdownItem } from '#ui/types'
import type { BaseWorkspace } from '#workspace/types'

const { currentWorkspace } = useWorkspaceSharedState()
const { result, load } = useListWorkspacesLazyQuery()

const actions = [
  {
    label: 'Create workspace',
    icon: 'i-heroicons-plus',
    slot: 'action',
    class: 'workspace-dropdown-create-btn px-2 py-1.5',
    click: () => {
      createWorkspaceModalVisible.value = true
    },
  },
]

const createWorkspaceModalVisible = ref(false)

const workspaceItems = computed<DropdownItem[]>(() => {
  return ((result.value?.workspaces || []) as unknown as DropdownItem[])
})

const orderedWorkspaceItems = computed<DropdownItem[]>(() => {
  const tempArr = [...workspaceItems.value]
  const index = tempArr.findIndex(obj => obj.id === currentWorkspace.value.id)

  if (index !== -1) {
    // Lấy đối tượng ra khỏi mảng
    const targetObject = tempArr.splice(index, 1)[0]
    // Thêm đối tượng vào đầu mảng
    tempArr.unshift(targetObject)
  }

  return tempArr
})

const changeWorkspace = (workspace: BaseWorkspace) => {
  window.location.href = `/w/${workspace.handle}`
}
</script>
