<template>
  <div
    class="flex flex-col flex-1 mt-4 px-4 m overflow-y-auto auto-hide-scrollbar"
  >
    <div
      class="flex items-center justify-between gap-2 text-gray-700 dark:text-white text-xs font-semibold mb-1"
    >
      <span
        v-if="can('dashboard.boards.create_boards') || activeBoards?.length"
        class="p-2"
      >
        Your boards
      </span>
      <UButton
        v-if="can('dashboard.boards.create_boards')"
        icon="i-heroicons-plus"
        color="gray"
        variant="ghost"
        size="sm"
        :ui="{
          color: {
            gray: {
              ghost: 'hover:bg-white',
            },
          },
        }"
        @click="openCreateBoardModal"
      />
    </div>
    <div v-if="loading" class="gap-1 flex flex-col">
      <div class="flex h-8 items-center">
        <USkeleton class="h-3 bg-white w-40" />
      </div>
      <div class="flex h-8 items-center gap-2">
        <USkeleton class="size-5 bg-white" />
        <USkeleton class="h-3 bg-white w-40" />
      </div>
      <div class="flex h-8 items-center gap-2">
        <USkeleton class="size-5 bg-white" />
        <USkeleton class="h-3 bg-white w-40" />
      </div>
    </div>
    <div v-else-if="activeBoards?.length" class="flex flex-1 flex-col gap-1">
      <a
        v-for="board in activeBoards"
        :key="board.id"
        :href="`/b/${board.handle}`"
        :class="{
          'px-2 py-1.5 hover:bg-white hover:text-gray-900 flex gap-2 items-center text-sm font-medium rounded': true,
          'bg-white text-gray-900 dark:bg-gray-800 dark:text-gray-200':
            board.active,
        }"
      >
        <div
          :style="{
            backgroundImage: `url(${getBackgroundUrl(
              board,
              BackgroundImageQuality.THUMB
            )})`,
          }"
          class="size-5 rounded border bg-center bg-cover"
        />
        <Tooltip :text="board.name" class="flex-1">
          <template #default="{ getTextRef }">
            <span
              :ref="getTextRef"
              class="line-clamp-1 break-all flex-1 text-left text-sm font-medium"
              data-test="sidebar-board-item"
            >
              {{ board.name }}
            </span>
          </template>
        </Tooltip>
        <Icon
          v-if="board.boardMember?.star"
          name="heroicons:star-solid"
          class="size-4 text-gray-400"
        />
      </a>
    </div>

    <div
      v-else-if="can('dashboard.boards.create_boards')"
      class="flex flex-col justify-center items-center h pt-6 pb-4 px-3 text-center border-2 border-dotted rounded-lg border-gray/32"
    >
      <span class="text-xs font-normal text-gray-500">
        Create your first board to organize and plan your work
      </span>
      <UButton
        class="text-gray-700 text-xs font-medium mt-4"
        variant="ghost"
        @click="openCreateBoardModal"
      >
        <Icon name="heroicons:plus" />
        New board
      </UButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LazyBoardCreateModal } from '#components'
import { BackgroundImageQuality } from '#board/constant'

const { currentWorkspace, currentBoard, activeBoards: activeBoardsState } = useWorkspaceSharedState()
const modal = useModal()
const route = useRoute()
const { can } = useWorkspaceAbility()

const { result, loading, load, refetch } = useUserBoardsLazyQuery(
  currentWorkspace.value.id
)

const activeHandle = ref(route.params.handle as string)

const activeBoards = computed(() => {
  return result.value?.memberBoards
    .filter((memberBoard) => !memberBoard.board.closed)
    .map((memberBoard) => {
      return {
        ...memberBoard.board,
        boardMember: memberBoard,
        active: currentBoard.value.id === memberBoard.board.id,
        starredAt: memberBoard.starredAt
          ? new Date(memberBoard.starredAt).getTime()
          : Infinity,
      }
    })
    .sort((a, b) => a.starredAt - b.starredAt)
})

const openCreateBoardModal = () => {
  modal.open(LazyBoardCreateModal, {
    // @ts-expect-error LazyBoardCreateModal expects these props
    workspaceId: currentWorkspace.value.id,
    onClose: () => {
      modal.close()
    },
    onCreated: () => {
      refetch()
      modal.close()
    },
  })
}

watch(
  () => route.params?.handle,
  (value) => {
    activeHandle.value = value as string
  }
)

watch(
  () => currentWorkspace.value.id,
  (value) => {
    refetch({
      workspaceId: value,
    })
  }
)

onMounted(() => {
  load()
})

watch(
  () => activeBoards.value,
  (val) => {
    if (val?.length) {
      activeBoardsState.value = val
    }
  }
)
</script>
