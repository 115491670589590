import type { RouteLocationNormalizedLoaded } from '#vue-router'
import { SETTINGS_PACK_QUERY } from '#settings-pack/schema'

export const useSettingsPackRouterWatcher = () => {
  const router = useRouter()
  const { setCurrentSettingsPack, currentWorkspace } = useWorkspaceSharedState()
  const { client } = useApolloClient()

  const getSettingsPack = async (route: RouteLocationNormalizedLoaded) => {
    if (!route.hash.includes('settings-pack') && !route.path.includes('settings-pack')) {
      return () => {}
    }

    if (!route.query.id) {
      return () => {}
    }

    const settingsPackQuery = client.watchQuery({
      query: SETTINGS_PACK_QUERY,
      variables: {
        id: route.query.id,
      },
      errorPolicy: 'ignore'
    })

    const unsubscribe = settingsPackQuery.subscribe((result) => {
      if (result.error || !result?.data?.settingsPack) {
        unsubscribe.unsubscribe()
        return router.push(`/w/${currentWorkspace.value.handle}/settings-packs`)
      }

      setCurrentSettingsPack(result.data.settingsPack)
    })

    return unsubscribe
  }

  router.beforeEach(async (to) => {
    const unsubscribe = await getSettingsPack(to)
    tryOnUnmounted(() => {
      if (typeof unsubscribe === 'function') {
        unsubscribe()
      } else {
        unsubscribe.unsubscribe()
      }
    })
    return true
  })

  return {
    getSettingsPack,
  }
}
