<template>
  <UDashboardLayout>
    <UDashboardPage>
      <UDashboardPanel grow>
        <div class="flex h-screen">
          <UDashboardPanel
            v-if="isExpanded"
            collapsible
            class="bg-gray-100 dark:bg-gray-900 z-20"
            :ui="{ wrapper: '!w-60' }"
          >
            <div :class="['flex items-center justify-between p-4']">
              <WorkspaceDropdown />
              <ExpandSideBarButton :is-main-button="true" />
            </div>
            <UDashboardSidebar
              :ui="{
                body: 'px-0',
              }"
            >
              <div class="space-y-1 px-4">
                <UTooltip
                  v-for="(link, index) in links"
                  :key="index"
                  :prevent="!link.tooltip?.text"
                  class="w-full"
                  :text="link.tooltip?.text"
                  :ui="{ middot: 'hidden', shortcuts: 'ml-1' }"
                  :popper="{ placement: 'top', arrow: true }"
                  :shortcuts="link.tooltip?.shortcuts"
                >
                  <NuxtLink
                    class="w-full text-sm px-2 py-1.5 font-medium text-gray-600 rounded-md flex items-center gap-2 cursor-pointer hover:bg-white dark:hover:bg-gray-800 dark:hover:text-gray-200"
                    :to="link.to"
                    :class="{
                      'bg-white text-gray-900 dark:bg-gray-800 dark:text-gray-200':
                        link.active,
                    }"
                  >
                    <Icon
                      :name="link.active ? `${link.icon}-solid` : link.icon as string"
                      :size="20"
                      :class="{
                        'text-primary-500': link.active,
                      }"
                    />
                    {{ link.label }}
                  </NuxtLink>
                </UTooltip>

                <div v-for="(link, i) in links" :key="i">
                  <div v-if="link.children" class="pl-4">
                    <NuxtLink
                      v-for="child in link.children"
                      :key="child.to?.toString()"
                      class="text-sm px-2 py-1.5 font-medium text-gray-600 rounded-md flex items-center gap-2 cursor-pointer hover:bg-white dark:hover:bg-gray-800 dark:hover:text-gray-200"
                      :to="child.to"
                      :class="{
                        'bg-white text-gray-900 dark:bg-gray-800 dark:text-gray-200':
                          child.active,
                      }"
                    >
                      <Icon
                        :name="child.active ? `${child.icon}-solid` : child.icon as string"
                        :size="20"
                        :class="{
                          'text-primary-500': child.active,
                        }"
                      />
                      {{ child.label }}
                    </NuxtLink>
                  </div>
                </div>
              </div>
              <SidebarListBoard />
              <!--  TODO: temporary hide it, uncomment this if needed -->
              <!-- <UButton
                  variant="ghost"
                  color="gray"
                  class="flex gap-2 p-2 mt-4 items-center"
                >
                  <Icon
                    name="heroicons:question-mark-circle"
                    class="size-6 text-gray-600"
                  />
                  <span class="text-gray-600 text-sm font-medium">Help & support</span>
                </UButton>
                 <UButton
                  variant="ghost"
                  color="gray"
                  class="flex gap-2 p-2 items-center"
                >
                  <Icon name="heroicons:bell" class="size-6 text-gray-600" />
                  <span class="text-gray-600 text-sm font-medium">Notification</span>
                </UButton> -->
              <UDivider class="sticky bottom-0" />
              <template #footer>
                <UserDropdown />
              </template>
            </UDashboardSidebar>
          </UDashboardPanel>
          <slot />
        </div>
      </UDashboardPanel>
    </UDashboardPage>
    <ClientOnly>
      <LazyUDashboardSearch :groups="groups" />
    </ClientOnly>
  </UDashboardLayout>
</template>

<script setup lang="ts">
import type { DashboardSidebarLinkExtend } from '#core/types'
import type { Command, Group } from '#ui/types'
import { ShortcutBusKey, ShortcutKeyEvent } from '#core/shortcut_keys_constants'

const route = useRoute()
const { isExpanded, toggleSidebar } = useDashboardSidebar()
const { getSettingsPack } = useSettingsPackRouterWatcher()
await getSettingsPack(route)
const { currentWorkspace } = useWorkspaceSharedState()
const { isOpen: isOpenBoardSetting, openSettings, closeSettings } = useBoardSettingsNavigator()

const activeLinkName = ref<string>('')
const { can } = useWorkspaceAbility()
const links = computed(() => {
  const handle = currentWorkspace.value?.handle
  const menuLinks: DashboardSidebarLinkExtend[] = [
    // {
    //   label: 'Search',
    //   icon: 'i-heroicons-magnifying-glass',
    //   to: `/w/${handle}/search`,
    //   pathName: 'w-handle-search',
    // },
    {
      label: 'Home',
      icon: 'i-heroicons-home',
      to: `/w/${handle}`,
      pathName: 'w-handle',
    },
    {
      label: 'Tasks',
      icon: 'i-heroicons-check-circle',
      to: `/w/${handle}/tasks`,
      pathName: 'w-handle-my-tasks',
    },
    {
      label: 'Boards',
      icon: 'i-heroicons-squares-2x2',
      to: `/w/${handle}/boards`,
      pathName: 'w-handle-boards',
    },
    {
      label: 'Workspace settings',
      icon: 'i-heroicons-cog-6-tooth',
      to: `/w/${handle}/settings`,
      pathName: 'w-handle-settings',
      activePathNames: ['w-handle-settings', 'w-handle-members', 'w-handle-roles', 'w-handle-settings-packs'],
      requiredPermissions: [
        'settings.general.edit_workspace_profile',
        'settings.general.manage_subscription',
        'settings.members.view_members',
        'settings.members.manage_members',
        'settings.members.manage_admin_members',
        'settings.members.manage_owner_members',
        'settings.members.manage_roles',
        'settings.settings_packs.manage_settings_packs',
      ],
    },
  ]
  return menuLinks.filter((link) => {
    if (link.requiredPermissions) {
      return link.requiredPermissions.some((permission) => can(permission))
    }

    return true
  }).map((link) => {
    if (link.activePathNames) {
      link.active = link.activePathNames.includes(activeLinkName.value)
    } else {
      link.active = link.pathName === activeLinkName.value
    }

    if (link.children) {
      link.children = link.children.map((child) => {
        child.active = child.pathName === activeLinkName.value
        return child
      })
    }

    return link
  })
})

const groups = computed<Group[]>(() => [
  {
    key: 'links',
    label: 'Go to',
    commands: links.value.map((link) => ({
      ...link,
      click: () => {
        link.click?.()
        navigateTo(link.to)
      },
      shortcuts: link.tooltip?.shortcuts,
    })) as unknown as Command[],
  },
])

watch(
  () => route.name,
  (val) => {
    activeLinkName.value = val as string
  },
  {
    immediate: true,
  }
)

const shortcutBusListeners = (message: ShortcutKeyEvent) => {
  switch (message) {
    case ShortcutKeyEvent.TOGGLE_BOARD_SETTINGS:
      return isOpenBoardSetting.value ? closeSettings() : openSettings()

      // TODO: Temporary hide it, uncomment this if needed
      // case ShortcutKeyEvent.NAVIGATE_BOARD_VIEW:
      //   if (!currentBoard.value.handle) {
      //     return
      //   }

      //   navigateTo(`/b/${currentBoard.value?.handle}`)
      //   break

      // case ShortcutKeyEvent.NAVIGATE_LIST_VIEW:
      //   if (!currentBoard.value.handle) {
      //     return
      //   }

      //   navigateTo(`/b/${currentBoard.value?.handle}/list`)
      //   break

      // case ShortcutKeyEvent.NAVIGATE_TIMELINE_VIEW:
      //   if (!currentBoard.value.handle) {
      //     return
      //   }

      //   navigateTo(`/b/${currentBoard.value?.handle}/timeline`)
      //   break

    case ShortcutKeyEvent.TOGGLE_EXPAND_SIDEBAR:
      toggleSidebar()
      break
  }
}

const shortcutBus = useEventBus<ShortcutKeyEvent>(ShortcutBusKey.WORKSPACE)

onMounted(() => {
  shortcutBus.on(shortcutBusListeners)
})

onUnmounted(() => {
  shortcutBus.off(shortcutBusListeners)
})

// Inject the shortcut key handler
usePasteFromClipboard()
useShortcutKeyHandler()
</script>
